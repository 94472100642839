import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import SEO from "../components/seo"

const ServicesIndex = ({ data }) => (
    <Layout>
        <SEO title="Services" />

        <PageTitle summary="Our team can provide you with expert advice on new sites, development opportunities, new build design, extensions or sympathetic refurbishments in both residential and commercial environments. Listening closely to our clients’ thoughts and ideas we give due consideration to the project ensuring the clients priority is achieved throughout."
        title="Services"/>

        <div className="mb-10 lg:mb-16 body-wrap">
            <p>We can manage your project and often provide principle designer and contract administration roles.</p>
            <p>Our initial consultation is the first meeting where we discuss your requirements and provide the necessary advice.</p>
        </div>

        {data.site.siteMetadata.services.map(link => (
            <div className="text-center" key={link.title}>
                <h2>{link.title}</h2>
                <p>{link.summary}</p>
            </div>
        ))}
        
    </Layout>
)

export const query = graphql`
    query ServicesQuery {
        site {
            siteMetadata {
                services {
                    title
                    summary
                }
            }
        }
    }
`

export default ServicesIndex
